import React, { useState } from 'react';
import 'react-bootstrap';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import accordionData from './careerData.json';
import './style.css';

// Add the icon to the library
library.add(faLocationDot);

function AccordionComponent() {
  const [activeTab, setActiveTab] = useState('Technology');

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleApplyClick = (email, id) => {
    const subject = `Job Application - ID: ${id}`;
    const mailtoURL = `mailto:${email}?subject=${encodeURIComponent(subject)}`;
    window.location.href = mailtoURL;
  };

  const filteredAccordionData = accordionData.filter(
    (item) => activeTab === 'All' || item.category === activeTab,
  );

  return (
    <div className="accordion-container">
      <h2 className="title">Open Positions</h2>
      <div className="nav-container">
        <ul className="nav nav-tabs">
          {['Technology', 'Marketing', 'Operations', 'Others', 'All'].map((tab) => (
            <li className="nav-item" key={tab}>
              <button
                type="button"
                className={`nav-link ${activeTab === tab ? 'active' : ''}`}
                onClick={() => handleTabClick(tab)}
              >
                {tab}
              </button>
            </li>
          ))}
        </ul>

        <div className="accordion accordion-flush mt-4" id="accordionFlushExample">
          {filteredAccordionData.map((item) => (
            <div className="accordion-item" key={item.id}>
              <h2 className="accordion-header">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target={`#flush-collapse-${item.id}`}
                  aria-expanded="false"
                  aria-controls={`flush-collapse-${item.id}`}
                >
                  <div className="job-container">
                    <div className="job-title">{item.job_title}</div>
                    <div className="job-info">
                      <span className="job-location">
                        <FontAwesomeIcon icon={faLocationDot} />
                        <span>{item.country}</span>
                        <span>|</span>
                        <span>{item.city}</span>
                        <span>|</span>
                        <span>{item.employmentStatus}</span>
                      </span>
                    </div>
                  </div>
                  <div className="apply-button">
                    <button
                      type="submit"
                      className="btn btn-primary apply"
                      onClick={() => handleApplyClick('hr@infenox.com', item.id)}
                    >
                      Apply
                    </button>
                  </div>
                </button>
              </h2>
              <div
                id={`flush-collapse-${item.id}`}
                className="accordion-collapse collapse"
                data-bs-parent="#accordionFlushExample"
              >
                <div className="accordion-body">
                  <strong>Description:</strong>
                  {' '}
                  {item.description}
                  <br />
                  <strong>Years of Experience:</strong>
                  {item.yearsOfExperience}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default AccordionComponent;
